import { ref, computed } from "vue";
import { defineStore } from "pinia";

export const useVideoStore = defineStore("mc-ui", () => {
  const _isProgressBarLoading = ref(false);
  const _isFullScreenOverlay = ref(false);
  const _isUploadDialogLoading = ref(false);

  const isProgressBarLoading = computed(() => _isProgressBarLoading.value);
  const isFullScreenOverlay = computed(() => _isFullScreenOverlay.value);
  const isUploadDialogLoading = computed(() => _isUploadDialogLoading.value);

  const enableLoadingFullScreen = () => {
    _isProgressBarLoading.value = true;
    _isFullScreenOverlay.value = true;
  };

  const stopLoading = () => {
    _isProgressBarLoading.value = false;
    _isFullScreenOverlay.value = false
  }

  const enableUploadDialogLoading = () => {
    _isUploadDialogLoading.value = true;
  }

  const stopUploadDialogLoading = () => {
    _isUploadDialogLoading.value = false;
  }

  return { enableLoadingFullScreen, stopLoading, enableUploadDialogLoading, stopUploadDialogLoading, isProgressBarLoading, isFullScreenOverlay, isUploadDialogLoading };
});
