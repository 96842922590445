<script setup>
import { computed } from "vue";

const props = defineProps({
  defaultPadding: {
    type: Boolean,
    required: false,
    default: true,
  },
  header: {
    type: String,
  },
  backgroundColor: {
    type: String,
    required: false,
    default: "var(--color-divider)",
  },
});

const styles = computed(() => {
  return {
    vars: {
      "--background-color": `${props.backgroundColor}`,
    },
  };
});
</script>

<template>
  <div :style="styles.vars">
    <h5 class="header">{{ props.header }}</h5>
    <div class="spacer"></div>
    <div
      :class="props.defaultPadding == true ? 'input-slot-padding' : ''"
      class="input-slot"
    >
      <slot name="input" />
    </div>
  </div>
</template>

<style scoped>
.header {
  opacity: 0.7;
}
.spacer {
  margin-top: 0.2rem;
}
.input-slot {
  font-size: 1rem;
  font-weight: bold;
  padding-inline: 1rem;
  border-radius: 5px;
  border: 1px solid var(--color-border);
  background-color: var(--background-color);
}
.input-slot-padding {
  padding-block: 0.5rem;
}
</style>
