<script setup>
import router from "@/routes";
import { useVideoStore } from "@/stores/videoUi";

const comicStore = useVideoStore();

</script>

<template>
  <div class="toolbar">
    <div class="toolbar-content">
      <v-btn
        @click="
          () => {
            router.back();
          }
        "
        class="radius-rounded"
        icon
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >
      <div class="toolbar-title">
        <slot name="title"></slot>
      </div>
      <slot name="actions"></slot>
    </div>
    <v-progress-linear
      :active="comicStore.isProgressBarLoading"
      indeterminate
      height="3"
      color="secondary"
    ></v-progress-linear>
  </div>
</template>

<style scoped>
.toolbar {
  position: relative;
  z-index: 10;
  border-bottom: 1px solid var(--color-divider);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}
.toolbar-content {
  align-items: center;
  padding: 1rem;
  display: flex;
  column-gap: 1rem;
}
.toolbar::before {
  z-index: -1;
  position: absolute;
  width: 100%;
  opacity: 0.9;
  height: 100%;
  content: "";
  background-color: var(--color-background);
}
.toolbar-title {
  min-width: 5rem;
  width: 100%;
  font-weight: bold;
  flex: 1;
  flex-basis: 100%;
}
</style>
