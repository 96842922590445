<script setup>
import { ref, watch } from "vue";
import InputItem from "@/components/motion-comics/InputItem.vue";
import * as _ from "lodash";
const props = defineProps({
  assets: {
    type: Array,
    required: true
  },
  visible: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(["update:visible", "add:bubble"]);

const currentSelectedId = ref("")
const bubbleText = ref("")

watch(() => props.visible, () => {
  if (props.visible) {
    bubbleText.value = "";
    currentSelectedId.value = ""
  }
})

const onApplyClick = () => {
  const current = currentSelectedId.value
  const bubble = props.assets.find(c => c.id == current)
  const obj = {
    asset: bubble,
    text: bubbleText.value,
    position: { x: 0, y: 0, prevX: 0, prevY: 0 }
  }
  emit("add:bubble", obj)
  emit("update:visible", false);
}

</script>

<template>
  <v-dialog
      @click:outside="$emit('update:visible', false)"
      @keydown.esc="$emit('update:visible', false)"
      v-model="props.visible"
      width="60vw"
  >
    <v-card rounded="lg" class="card">
      <div>
        <div class="header">
          <h3 class="title">Add Chat Bubble</h3>
          <v-btn
              color="var(--color-border-secondary)"
              icon
              @click="$emit('update:visible', false)"
              class="btn-close"
          ><v-icon>mdi-close</v-icon></v-btn>
        </div>

        <div class="spacer"/>

        <div class="divider" />

        <div>
          <v-radio-group v-model="currentSelectedId" v-for="item in props.assets" :key="item.id" >
            <div class="item">
              <v-radio :key="item.id" :value="item.id"  />
              <img :src="item.assetName"/>
            </div>
          </v-radio-group>
        </div>

        <div class="input-container">

          <InputItem header="Enter text">
            <template #input>
              <textarea v-model="bubbleText" variant="flat" no-resize="true" class="summary-input"/>
            </template>
          </InputItem>

        </div>

        <div class="spacer-2"></div>

        <div class="flex-row flex-row-end">
          <v-btn :disabled="_.isEmpty(currentSelectedId)" @click="onApplyClick" color="secondary">Apply</v-btn>
        </div>

      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.header {
  display: flex;
}
.title {
  flex: 1
}
.divider {
  height: 1px;
  background-color: var(--color-border);
}
.btn-close {
  transform: translate(10px, -5px);
  border-radius: 1rem;
  border: 1px solid var(--color-border);
}
.input-container {
  text-align: left;
}
.item {
  display: flex;
}
img {
  width: 10rem;
}
.card {
  background-color: var(--color-divider);
  padding: 2rem;
}
.summary-input {
  overflow-y: auto;
  resize: none;
}
</style>
