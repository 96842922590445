<script setup>
import "@/assets/base.css";
import "@/assets/utility.css";
import InputItem from "@/components/motion-comics/InputItem.vue";
import {ref, watch, onMounted, computed} from "vue";
import * as _ from "lodash";
import Toolbar from "@/components/motion-comics/Toolbar";
import SpeechBubbleDialog from "@/components/comic-studio/dialog/SpeechBubbleDialog";
import axios from "axios"
import draggable from 'vuedraggable'
import Vue from 'vue';
import domtoimage from 'dom-to-image';

Vue.use(draggable)

const TOTAL_PROGRESS_TIME_SECONDS = 60;

const characterNames = ref([]);
const prompt = ref("");
// https://comic-assets.s3.ap-south-1.amazonaws.com/crecoAI/,_niya.png
const imageUrl = ref("");

const isLoading = ref(false);
const progress = ref(0);

const imageRef = ref();

const removeItem = (item) => {
  const items = _.filter(characterNames.value, i => i != item.avatarName)
  characterNames.value = items;
}

const bubbleAssets = [{
  id: "bubble-1",
  assetName: "https://comic-assets.s3.ap-south-1.amazonaws.com/7_char_assets/chat-bubble-1.png"
}]
const speechBubbleDialogVisible = ref(false);


const currentDraggingItem = ref();
// const bubbleItems = ref([{
//   asset: {
//     id: "bubble-1",
//     assetName: "chat-bubble-1.png"
//   },
//   position: {
//     x: 0, y: 0, prevX: 0, prevY: 0
//   }
// }])

const bubbleItems = ref([])

const items = ref([])

const isDragging = ref(false)
const initialX = ref(0)
const initialY = ref(0)
const currentX = ref(0)
const currentY = ref(0)

const isDownloadEnabled = computed(() => !_.isEmpty(imageUrl.value));
const isGenerateEnabled = computed(() => !_.isEmpty(characterNames.value) && !_.isEmpty(prompt.value));

const delay = (millis) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve()
    }, millis)
  })
}

const onGenerate = async () => {
  isLoading.value = true;
  imageUrl.value = "";
  const characters = characterNames.value.map(it => it.avatarName).join(",");
  await axios.get(`https://gamma.pratilipicomics.com/comic-crecoai/generate?character=${characters}&prompt=${prompt.value}`)
     // await Promise.resolve()
      .then(async (response) => {
        let image = response.data.promptImage;

        const step = (100 * 4) / TOTAL_PROGRESS_TIME_SECONDS
        let seconds = 0

        while (true) {
          await delay(4000)
          try {
            const {data, status} = await axios.get(image)
                .catch();
            if (status >= 200 && status < 300)
              break;
          } catch (error) {}
          seconds += 4
          console.log("Current: " + progress.value + ", Step: " + step + ", sec: " + seconds)
          progress.value += step
        }

        imageUrl.value = image;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        progress.value = 0;
        isLoading.value = false;
      });
}

const onBubbleAddClick = (data) => {
  bubbleItems.value.push(data);
}

function startDragging(e, item) {
  isDragging.value = true
  initialX.value = e.clientX
  initialY.value = e.clientY
  console.log(`Drag: ${e.clientX} : ${e.clientY}`)
  currentDraggingItem.value = item
}

function drag(e) {
  if (!isDragging.value) return
  currentX.value = e.clientX - initialX.value
  currentY.value = e.clientY - initialY.value
  if (currentDraggingItem.value) {
    currentDraggingItem.value.position.x = currentX.value
    currentDraggingItem.value.position.y = currentY.value

    currentDraggingItem.value.position.prevX = currentX.value
    currentDraggingItem.value.position.prevY = currentY.value
  }
}

function stopDragging() {
  isDragging.value = false;
  currentDraggingItem.value = null
}

const onDownloadClick = () => {
  console.log(imageRef.value)
  domtoimage.toPng(imageRef.value, {})
      .then(dataUrl => {
        let link = document.createElement('a');
        link.download = 'image.png';
        link.href = dataUrl;
        link.click();
      })
}


onMounted(() => {
  isLoading.value = true
  axios.get("https://gamma.pratilipicomics.com/comic-crecoai/avatars")
      .then((response) => response.data.avatars)
      .then((avatars) => {
        items.value = avatars;
      })
      .finally(() => {
        isLoading.value = false;
      })
})
</script>

<template>
  <v-app>
    <v-main class="main-class">
      <div>
        <Toolbar>
          <template #title>
            <h3>Comic Studio</h3>
          </template>
          <template #actions>
            <v-btn :disabled="isLoading || !isGenerateEnabled" @click="onGenerate" color="secondary">
              Generate
            </v-btn>
            <v-btn fab small elevation="2" @click="onDownloadClick" :disabled="isLoading || !isDownloadEnabled"
                   color="info" class="btn">
              <v-icon color="surface">mdi-download-outline</v-icon>
            </v-btn>
          </template>
        </Toolbar>
        <v-progress-linear
            :active="isLoading"
            indeterminate
            height="3"
            color="secondary"
        ></v-progress-linear>
        <section>
          <div class="con">
            <v-card color="var(--color-surface)" rounded="lg" class="card">
              <div class="card-container">
                <InputItem header="Character Name">
                  <template #input>
                    <v-autocomplete
                        :items="items"
                        inputmode="none"
                        rounded
                        v-model="characterNames"
                        chips
                        multiple
                        color="blue-grey"
                        item-text="avatarName"
                        item-value="avatarName"
                    >
                      <template v-slot:selection="data">

                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="removeItem(data.item)"
                        >
                          <v-avatar left>
                            <v-img :src="data.item.avatarImage"></v-img>
                          </v-avatar>
                          {{ data.item.avatarName }}
                        </v-chip>

                      </template>

                      <template v-slot:item="data">


                        <v-list-item-avatar>
                          <img :src="data.item.avatarImage">
                        </v-list-item-avatar>
                        <h4>{{ data.item.avatarName }}</h4>
                      </template>
                    </v-autocomplete>
                  </template>
                </InputItem>
                <div class="spacer"></div>
                <InputItem class="input-summary" header="Prompt">
                  <template #input>
                    <textarea v-model="prompt" variant="flat" no-resize="true" class="summary-input"/>
                  </template>
                </InputItem>

                <div class="spacer"></div>
                <div class="spacer"></div>
              </div>
            </v-card>
            <v-progress-linear height="1rem" v-if="isLoading" :value="progress" color="secondary" striped rounded/>
            <div v-if="!isLoading" ref="imageRef" class="img-container">
              <img crossorigin="anonymous" :src="imageUrl" class="img-class" @mousedown="startDragging" @mousemove="drag" @mouseup="stopDragging" draggable="false"  />
              <div v-for="item in bubbleItems" :key="item.asset.id" class="bubble-container" >
                <div @mousedown="startDragging($event, item)" @mousemove="drag" @mouseup="stopDragging"
                     :style="{position: 'absolute', left: item.position.x + 'px', top: item.position.y + 'px'}">
                  <div class="bubble-img-item">
                    <img width="200px" height="100px" :src="item.asset.assetName" draggable="false"/>
                    <div class="bubble-img-item-text">{{ item.text }}</div>
                  </div>

                </div>
              </div>
            </div>


           <div  class="footer" v-if="!_.isEmpty(imageUrl)">
             <v-divider/>

             <div>
               <v-btn @click="speechBubbleDialogVisible = true" class="btn text-green">
                 <v-icon color="info" left dark>mdi-chat</v-icon>
                 Add Speech Bubble
               </v-btn>
             </div>
             <div class="spacer-0-5"></div>
           </div>
<!--            <div class="img-container">
              <v-progress-linear height="1rem" v-if="isLoading" :value="progress" color="secondary" striped rounded/>
              <div @mousedown="onDragStart" @mousemove="onDrag" @mouseup="onDrop" class="img-item">
                <img :src="imageUrl" class="img-class"/>
              </div>
              <div v-for="item in bubbleItems" :key="item.asset.id" class="bubble-container" >
                <div :id="item.asset.id"
                     :style="{position: 'absolute', transform: `translate(${item.position.x}px, ${item.position.y}px)`}"
                     draggable="true"
                     @dragstart="onDragStart($event, item)"
                     @drag="onDrag($event, item)"
                     @dragend="onDrop($event, item)"
                     class="bubble-item"
                >
                  <img width="200px" height="100px" src="@/assets/chat-bubble-1.png"/>
                </div>
              </div>
            </div>-->
          </div>
        </section>
      </div>

      <SpeechBubbleDialog
          :assets="bubbleAssets"
          :visible="speechBubbleDialogVisible"
          @update:visible="speechBubbleDialogVisible = $event"
          @add:bubble="onBubbleAddClick($event)"/>
    </v-main>
  </v-app>

</template>

<style scoped>
h2,
h3 {
  color: var(--color-text)
}

.btn {
  border-radius: 2rem;
}

.text-green {
  color: var(--color-accent)
}

.img-container {
  border: 2px solid var(--color-divider);
  border-radius: 0.5rem;
  display: flex;
  position: relative;
  /*justify-content: center;*/
  /*align-items: center;*/
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.img-item {
}

.img-class {
  width: 100%;
  border-radius: 0.5rem;
  object-fit: contain;
}

.bubble-img-item {
  position: relative;
}

.bubble-img-item-text {
  position: absolute;
  top: 20%;
  left: 20%;
}

.con {
  margin: 1rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.main-class {
  text-align: start;
  background-color: var(--color-background);
}

.card {
  padding: 1rem;
}

.summary-input {
  overflow-y: auto;
  resize: none;
}
.speech-bubble {
  position: absolute;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}
</style>
